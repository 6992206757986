import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StyledSection33, Wrapper33 } from "./Winegrid.styles"
import { Link } from "gatsby"
import Placeholder from "../../assets/images/newpl.jpg"

const RegionGrid = ({ heading, bigData }) => {
  // const teaser = truncate(featured.Content, {
  //   length: 300,
  //   separator: /,?\.* +/,
  // })

  return (
    <Wrapper33>
      {heading && <h2 id="wines">{heading}</h2>}
      <StyledSection33>
        {bigData.map((e, index) => (
          <div key={index} className="wrapper shadow-md">
            <Link to={`/${e.slug}/`}>
              {e.image && e.image !== "null" ? (
                <GatsbyImage
                  image={e.image}
                  className="imagewrapper"
                  alt={heading + "wine region"}
                />
              ) : (
                <img
                  src={Placeholder}
                  alt="Temporary placeholder"
                  className="imagewrapper"
                />
              )}
              <h2 className="max-w-[292px]">{e.title}</h2>
            </Link>
          </div>
        ))}
      </StyledSection33>
    </Wrapper33>
  )
}

export default RegionGrid
