import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import BlogGrid from "../components/BlogFeatured/BlogGrid"

import "react-responsive-carousel/lib/styles/carousel.min.css"

import HeadingCaroussel from "../components/HeadingCaroussel"
import { Helmet } from "react-helmet"
import RegionGrid from "../components/RegionGrid/RegionGrid"

// if (typeof window !== "undefined") {
//   // eslint-disable-next-line global-require
//   require("smooth-scroll")('a[href*="#"]')
// }

export default function IndexPage({ data }) {
  const CarouData = data.Caroussel.carroussel_blog.news_blogs
    .map(e => ({
      title: e.Title,
      image: e.MainMedia.localFile.childImageSharp.gatsbyImageData,
      slug: `/news/${e.slug}/`,
      date: e.created_at,
    }))
    .reverse()
    .slice(0, 6)

  //console.log(data)

  const domaines = data.Domaine.nodes.map(e => ({
    title: e.Domaine,
    slug: e.Slug,
    image: e.MainImage?.localFile?.childImageSharp?.gatsbyImageData,
    // ? e.Image.childImageSharp.fixed
    // : null,
  }))

  const news = data.News.nodes.map(e => ({
    title: e.Title,
    slug: `news/${e.slug}`,
    image: e.MainMedia?.localFile?.childImageSharp?.gatsbyImageData,
  }))

  CarouData.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date) - new Date(a.date)
  })

  return (
    <Layout relative={false}>
      <Helmet>
        <meta
          name="google-site-verification"
          content="SBl8UFjN56cQr9mllU5pYLTtF7hZrjJDlZ2AGdLdNGY"
        />
      </Helmet>
      <Seo title="European Wine Distributor" />
      <HeadingCaroussel data={CarouData} />
      <h2 className="headline_mainPage text-center mt-[60px] bold">
        a taste of who we represent
      </h2>
      <RegionGrid bigData={domaines} />
      <h2 className="headline_mainPage text-center mt-[20px] bold">
        Our Latest Articles
      </h2>
      <RegionGrid bigData={news} />
    </Layout>
  )
}

export const query = graphql`
  query {
    Caroussel: strapiHomePage {
      carroussel_blog {
        news_blogs {
          MainMedia {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 60)
              }
            }
          }
          Title
          slug
          created_at
        }
      }
    }
    Domaine: allStrapiDomaines(
      sort: { order: DESC, fields: published_at }
      limit: 12
    ) {
      nodes {
        Domaine
        Description
        MainImage {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 300, quality: 60, placeholder: BLURRED)
            }
          }
        }
        id
        Slug
      }
    }
    News: allStrapiNewsBlog(
      sort: { order: DESC, fields: published_at }
      limit: 6
    ) {
      nodes {
        id
        slug
        Title
        MainMedia {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 300, quality: 60, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
