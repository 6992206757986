import styled from "styled-components"

export const StyledSection33 = styled.section`
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  gap: 1rem;
  row-gap: 4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;


  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .wrapper {
    background-color: white;
    transform(scale(0.8))
    box-shadow: rgb(40, 41, 54) 0px 0px 0.5em -0.25em;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin: 0px auto;
    overflow: hidden !important;
    &:hover {
      opacity: 0.8;
      transform: scale(1);
      transition: 0.2s ease-out;
      z-index: 50;
    }
  }

  .featured {
    grid-column: 2/3;
    grid-row: 1/-1;
  }
  h2 {
    font-size: var(--headingsec);
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
  }

  .wrapper {
    width: 100%;
    max-width: 292px !important;
   
  }

  .imagewrapper {
    width: 100%;
    height: 194px !important;
    object-fit: cover;
  }

  @media only screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;

  }
  @media only screen and (max-width: 380px) {
    max-width: 100vw;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  } 
`

export const Wrapper33 = styled.div`
  background: #fefefe;

  text-align: center;
  padding: 40px 0;
  color: #fff;
  #wines {
    font-size: 3.2rem;
  }

  h2 {
    padding: 2rem;
  }
`
