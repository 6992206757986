import styled from "styled-components"

export const StyledSection = styled.section`
  .textWrapper {
    padding: 20px;
  }
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 0 1vw;
  .featured {
    grid-column: 2/3;
    grid-row: 1/-1;
  }
  .gatsby-image-wrapper {
    width: 100%;
    overflow: hidden !important;
  }
  p {
    color: #797979;
    margin-top: 2rem;
    text-align: justify;
    font-size: 1.1rem;
    font-weight: 200;
  }
  h2 {
    font-size: var(--headingsec);
    text-align: center;
  }
  .title-blog {
    position: relative;
  }
  .title-blog:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 10px auto;
    margin-bottom: 40px;
    width: 20px;
    height: 1px;
    background: var(--black);
  }
  .smaller-w {
    overflow: hidden !important;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 0.5em -0.25em #282936;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .miniImg {
    overflow: hidden !important;
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin: 0 !important;
  }
  .featuredPost {
    overflow: hidden !important;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 0.5em -0.25em #282936;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 1rem;
  }
  @media only screen and (max-width: 920px) {
  }
  @media only screen and (max-width: 920px) {
    width: 98%;
    overflow: hidden;
    gap: 1.5rem;
    margin: 0 auto;
    padding: 0;

    flex-direction: column-reverse;
    .featured {
      grid-column: 1/-1;
      grid-row: 1/2;
    }
    .featured h2 {
      margin-bottom: 20px;
    }
    .featuredPost {
      max-width: 98%;
      margin: 0 auto;
    }
    .smaller-w {
      max-width: 98%;
      padding: 0;
    }
    .miniImg {
      max-width: 100%;
      height: 200px;
    }
    .featuredImg {
      width: 100%;
      height: 300px !important;
    }
  }
`

export const Wrapper = styled.div`
  background: #fefefe;
  text-align: center;
  padding: 40px 0;
  color: #fff;
  @media only screen and (max-width: 920px) {
    padding: 0;
  }
`
