import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StyledSection, Wrapper } from "./BlogGrid.styles"
import { FeaturedBlogQuery } from "./BlogQuery"
import truncate from "lodash.truncate"
import { SmallBlog } from "./SmallBlog"
import { Link } from "gatsby"
import removeMd from "remove-markdown"

const BlogGrid = () => {
  const data = FeaturedBlogQuery()
  //console.log(data)
  const featured = data.featuredBlog.nodes[0].featuredBlog.FeaturedLarge
  const pleb = data.featuredBlog.nodes[0].featuredBlog.Choose4
  const teaser = removeMd(
    truncate(featured.Content, {
      length: 600,
      separator: /,?\.* +/,
    }),
    { useImgAltText: false }
  )
  return (
    <Wrapper id="featured-blog">
      <StyledSection>
        <Link to={`/news/${featured.slug}/`} className="featured">
          <div className="featuredPost">
            <GatsbyImage
              image={
                featured.MainMedia.localFile.childImageSharp.gatsbyImageData
              }
              className="featuredImg"
              alt={featured.Title}
            />

            <div className="textWrapper">
              <h2 className="title-blog">{featured.Title}</h2>
              <p>{teaser}</p>
            </div>
          </div>
        </Link>

        {pleb.map((blog, index) => (
          <SmallBlog
            heading={truncate(blog.Title, {
              length: 20,
              separator: /,?\.* +/,
            })}
            image={blog.MainMedia.localFile.childImageSharp.gatsbyImageData}
            slug={blog.slug}
            content={blog.Content}
            key={index}
          />
        ))}
      </StyledSection>
    </Wrapper>
  )
}

export default BlogGrid
